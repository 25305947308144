import { useRouter } from "next/router";
import Script from "next/script";

import { FB_PIXEL_ID } from "@lib/fpixel";

export const ThirdPartyScripts = () => {
  const router = useRouter();
  const shouldLoadScripts =
    router.pathname.startsWith("/login") || router.pathname.startsWith("/go");

  if (!shouldLoadScripts || process.env.PRACTICE_ENV !== "production") {
    return null;
  }

  return (
    <>
      {/* Start of HubSpot Embed Code */}
      <Script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="https://js.hs-scripts.com/8810202.js"
      />
      <Script
        id="hs-conversations-settings"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            window.hsConversationsSettings = {
              inlineEmbedSelector: "#live-chat",
            };
          `,
        }}
      />
      {/* Global Site Code Pixel - Facebook Pixel */}
      <Script
        id="fbpixel-script-loader"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${FB_PIXEL_ID});
          `,
        }}
      />
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src={`https://www.facebook.com/tr?id=${FB_PIXEL_ID}&ev=PageView&noscript=1`}
        />
      </noscript>
      {/* LinkedIn */}
      <Script
        id="linkedin-script-loader"
        dangerouslySetInnerHTML={{
          __html: `
            _linkedin_partner_id = "3203177";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
            (function(){var s = document.getElementsByTagName("script")[0];
            var b = document.createElement("script");
            b.type = "text/javascript";b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);})();
          `,
        }}
      />
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=3203177&fmt=gif"
        />
      </noscript>
    </>
  );
};
